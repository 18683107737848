<template>
  <div>
    <button
      class="back-button"
      @click="changeMenuVisibility(true)"
    >
      Voltar
    </button>
    <post
      v-if="
        slug"
    />
    <blog v-else />
  </div>
</template>

<script>
import Post from '@/components/Post.vue';
import Blog from '@/components/Blog.vue';
import { mapActions } from 'vuex';

export default {
    name: 'PostPage',
    components: {
        Post,
        Blog,
    },
    beforeRouteEnter(to, from, next) {
        next((vm) => {
            vm.changeMenuVisibility(false);
            vm.changeIsBlog(false);
        });
    },
    computed: {
        slug() {
            return this.$router.currentRoute.params.slug;
        },
    },
    methods: {
        ...mapActions(['changeMenuVisibility', 'changeIsBlog']),
    },
};
</script>

<style>

/* left: -150px to 0 animation */
@keyframes slideInFromLeft {
  0% {
    left: -150px;
  }
  100% {
    left: 0;
  }
}

.back-button {
  background-color: #f6a736;
  color: #1A2F50;
  padding: 10px;
  border: none;
  cursor: pointer;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
  position: fixed;
  z-index: 10;
  animation: slideInFromLeft 0.5s;
}

@media (max-width: 768px) {
  .back-button {
    top: 0;
    transform: translate(0, 0);
  }
}
</style>
